import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/(app)": [~7,[2],[3]],
		"/(app)/accounts/login": [8,[2],[3]],
		"/admin": [9,[4],[5]],
		"/admin/accounts": [~10,[4],[5]],
		"/admin/announcements": [~11,[4],[5]],
		"/admin/events": [~12,[4],[5]],
		"/admin/place/edit": [~13,[4],[5]],
		"/admin/place/history/[[date]]": [~14,[4],[5]],
		"/embeds/events": [~15],
		"/place": [~16,[6]],
		"/place/embed": [~17,[6]],
		"/wheel": [~18]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';